<template>
  <v-row class="ma-0" :class="{'flex-nowrap': $vuetify.breakpoint.smAndUp}">
    <v-sheet class="pa-0" style="margin: 20px 30px" width="200">
      <v-text-field
        v-model="searchDesigner"
        outlined
        single-line
        class="rounded-lg text-black fz-14 mb-5 font-weight-medium input-border"
        style="width: 200px"
        hide-details
        dense
      ></v-text-field>
      <v-card
        v-for="(item, index) in designersArr"
        :key="item.id"
        :ripple="false"
        @click="selectedDesigner = item"
        height="58"
        elevation="0"
        :class="{'active': +item.id === (selectedDesigner ? +selectedDesigner.id : false), 'bb-lgray': designers[index + 1] !== selectedDesigner}"
        style="padding-left: 13px"
        class="d-flex align-center account-list-item"
      >
        <v-avatar
            :color="item.avatarUrl ? 'transparent' : 'primary'"
            size="34"
            class="mr-3"
          >
            <img
              v-if="item.avatarUrl"
              :src="item.avatarUrl"
            >
            <span v-else class="fz-10 white--text font-weight-bold">{{ item.businessInitials }}</span>
          </v-avatar>
        <span class="fz-14 font-weight-medium lh-20 text-black">{{ item.businessName || item.gmail }}</span>
      </v-card>
    </v-sheet>
    <dashboard-body
      v-if="dashboardRender"
      :id="selectedDesigner ? selectedDesigner.id : null"
      :deadlineShow="false"
    />
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
  </v-row>
</template>

<script>
import DashboardBody from '@/components/App/Dashboard/DashboardBody'
import VSnackbars from 'v-snackbars'
import gql from 'graphql-tag'
export default {
  name: 'DesignerAccount',
  components: { 'v-snackbars': VSnackbars, DashboardBody },
  data() {
    return {
      searchDesigner: '',
      designers: [],
      messages: [],
      selectedDesigner: null
    }
  },
  computed: {
    dashboardRender() {
      return this.$store.getters.getMe && (this.selectedDesigner || this.$store.getters.getMe.roles.filter(el => el === 'superuser' || el === 'staff').length > 0)
    },
    designersArr() {
      let arr = this.designers
      if (this.searchDesigner) {
        arr = arr.filter(el => el.businessName.toLowerCase().includes(this.searchDesigner.toLowerCase()))
      }
      return arr.filter((el, index) => index < 20)
    }
  },
  methods: {
    async getDesigners() {
      await this.$apollo.query({
        query: gql`query getDesigners {
          team {
            paginatedDesigners(input: {pageSize: 999}) {
              data {
                id
                businessName
                avatarUrl
                businessInitials
                gmail
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.designers = data.data.team.paginatedDesigners.data
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  mounted() {
    if (this.$store.getters.getMe) this.selectedDesigner = this.$store.getters.getMe.designerUser
    this.getDesigners()
  },
  watch: {
    '$store.state.me': {
      handler() {
        this.selectedDesigner = this.$store.getters.getMe.designerUser
      }
    }
  }
}
</script>

<style lang="scss">
.account-list-item {
  border-radius: 0 !important;
  &:before {
    content: none;
  }
  &.active {
    border-radius: 8px !important;
    border-bottom: 1px solid transparent !important;
    background-color: #EEF2F7;
  }
}
</style>
